@import url(https://fonts.googleapis.com/css2?family=Cabin&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  background: rgb(0,0,0);
  color: white;
  font-family: 'Cabin', sans-serif;
}

.header{
  display: flex;
  justify-content: center;
  
}

.header img{
  height: 30%;
  width: 40%;
}

.tickets{
  background: white;
  color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.holeshot img{
  width: 70%;
}

.info{
  width: 40%;
  align-items: center;
}

.info-date{
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-date h1{
  margin-left: 1.5rem;
  font-size: 2.3rem;
}

.link-container{
  width: 40%;
  align-items: center;
}

.link-container a{
  text-decoration: none;
  font-size: 1.5rem;
}

.link{
  background: black;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem; 
  box-shadow: 10px 10px 15px 5px rgba(0,0,0,0.61);
  text-decoration: none;
  
  transition: .7s;
}

.link:hover{ 
  box-shadow: 7px 7px 17px 7px rgba(0,0,0,0.7);
  transition: .7s;
  
}

.link:hover > .link-text{
  color: #d13238;
  transition: .7s;
}


.link-text{
  text-decoration: none;
  color: white;
  transition: .7s;
}



.showcase{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.video{
  width: 95%;
  height: 95%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.showcase-text{
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 80%;
}
.showcase-text h1{
  text-decoration: underline;
  color: #e91e25;
}

.showcase-text p{
  margin-right: 3rem;
  line-height: 1.5rem;
  font-size: 1.1rem;
}

.footer{
  background: white;
  color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.social{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.social a{
  text-decoration: none;
  color: black;
  margin-left: .5rem;
  margin-right: .5rem;
  transition: .5s;
}

.social a:hover{
  color: rgb(44, 44, 44);
  transition: .5s;
}

.footer-img{
  width: 20%;
}

.footer-img p{
  font-size: .8rem;
}

.footer-img img{
  width: 50%;
  height: 15%;
}


/*Mobile*/
@media screen and (max-width: 800px){
  .showcase{
    flex-direction: column;
    
  }

  .holeshot{
    margin-top: 1rem;
  }
  .showcase-text p{
    margin: 0;
  }
}

@media screen and (max-width: 700px){
  .header img{
    height: 50%;
    width: 70%;
  }

  .info-date h1{
    font-size: 1.5rem;
  }

  .link{
    padding: .5rem;
  }
  .link-container a{
    font-size: 1rem;
  }

  .showcase-text{
    text-align: center;
  }
}

@media screen and (max-width: 650px){
  .video{
    text-align: center;
  }

  .footer-img img{
    width: 70%;
    height: 55%;
  }

}

@media screen and (max-width: 550px){
  .header img{
    height: 100%;
    width: 100%;
  }

  .tickets{
    flex-direction: column-reverse;
    text-align: center;
  }

  .link{ 
  box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.61);
  }

  .link-container{
    text-align: center;
    width: auto;
  }

  .info-date{
    flex-direction: column;
    margin-top: 2rem;
  }
  .info-date h1{
    margin: 0;
  }
  .footer-img img{
    width: 80%;
    height: 65%;
  }

  .showcase-text h1{
    font-size: 2rem;
  }
}
